import React from "react"
import "./FooterV2.scss"
import { useFooterData } from "hooks"
import { useFooterV2Data } from "hooks/useFooterV2Data"
import { PrismicLink } from "@prismicio/react"
import { GatsbyImage } from "gatsby-plugin-image"

const FooterV2 = () => {
  const data = useFooterV2Data()
  const {
    companyresources = [],
    aboutcompany = [],
    companylogo = {},
    sociallinks = [],
    copyright = {},
    soc2 = {},
  } = data
  return (
    <div id="footer-new-home" className="footerNew">
      <div className="items-container">
        <div className="logo-section">
          {companylogo?.gatsbyImageData && (
            <div className="image">
              <GatsbyImage
                alt={companylogo?.alt}
                image={companylogo?.gatsbyImageData}
                objectFit="contain"
              />
            </div>
          )}

          <div className="resources-section">
            <div className="action-items">
              <div className="header">Resources</div>
              <div className="items">
                {companyresources?.map((item: any, index: number) => (
                  <a
                    href={item.url_field?.url}
                    key={index}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="value" key={index}>
                      {item?.field_name}
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className="action-items">
              <div className="header">Company</div>
              <div className="items">
                {aboutcompany?.map((item: any, index: number) => (
                  <a
                    href={item.url_field?.url}
                    key={index}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div className="value" key={index}>
                      {item?.field_name}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="logo">
            <div className="soc2">
              <GatsbyImage
                alt={soc2.alt}
                image={soc2.gatsbyImageData}
                objectFit="contain"
              />
            </div>
            <div className="image-section">
              <div
                style={{
                  display: "flex",
                  gap: "0.125rem",
                  alignItems: "center",
                }}
              >
                {sociallinks?.map((item: any, index: number) => (
                  <a key={index} target="_blank" href={item?.iconlink?.url}>
                    <div key={index}>
                      <GatsbyImage
                        alt={item?.icon?.alt}
                        image={item?.icon?.gatsbyImageData}
                        objectFit="contain"
                      />
                    </div>
                  </a>
                ))}
              </div>
              <div className="soc">
                <GatsbyImage
                  alt={soc2.alt}
                  image={soc2.gatsbyImageData}
                  objectFit="contain"
                />
              </div>
            </div>
            <div className="copyright">{copyright?.text}</div>
          </div>
        </div>
        <div className="resources-section">
          <div className="action-items">
            <div className="header">Resources</div>
            <div className="items">
              {companyresources?.map((item: any, index: number) => (
                <a
                  href={item.url_field?.url}
                  key={index}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <div className="value" key={index}>
                    {item?.field_name}
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="action-items">
            <div className="header">Company</div>
            <div className="items">
              {aboutcompany?.map((item: any, index: number) => (
                <a
                  href={item.url_field?.url}
                  key={index}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <div className="value" key={index}>
                    {item?.field_name}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterV2
