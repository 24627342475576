import { SliceZone } from "@prismicio/react"
import { Footer, Header, sliceComponents, sliceComponentV2 } from "components"
import { useHeroSectionAndCustomerLogoData } from "hooks"
import * as React from "react"
import CookieBanner from "./cookieBanner/cookieBanner"
import FooterV2 from "./FooterV2/FooterV2"
import Navigation from "./Navigation/Navigation"

interface ILayoutProps {
  children: React.ReactNode
  isHomePage?: boolean
}

const Layout: React.FunctionComponent<ILayoutProps> = ({
  children,
  isHomePage,
}) => {
  const sectionData = useHeroSectionAndCustomerLogoData(!!isHomePage)

  return (
    <>
      <div className="position-relative overflow-hidden">
        <Navigation />
        {/* <Header isHomePage={isHomePage} /> */}
        {/* {isHomePage && sectionData.body ? (
          <div className="pb-160">
            <SliceZone
              slices={sectionData.body}
              components={sliceComponentV2}
              defaultComponent={() => null}
            />
          </div>
        ) : null} */}
      </div>
      <main className="position-relative">{children}</main>
      {/* <Footer /> */}
      <FooterV2 />
      <CookieBanner />
    </>
  )
}

export default Layout
