import { graphql } from "gatsby"
import React from "react"
import "./BottomBanner.scss"

interface BottomBannerProps {
  slice: any
}

const BottomBanner: React.FC<BottomBannerProps> = ({ slice }) => {
  const {
    title = {},
    banner_link_text = "",
    subtitle = {},
    banner_background_image = {},
  } = slice?.primary

  return (
    <div
      style={{
        backgroundImage: `url(${banner_background_image?.url})`,
      }}
      className="BottomBanner"
    >
      <div className="banner-link-text">{banner_link_text}</div>
      <div className="title">{title?.text}</div>
      <div className="title">{subtitle?.text}</div>
    </div>
  )
}

export default BottomBanner

export const query = graphql`
  fragment bottomBanner on PrismicTeamblev2homepageDataBodyBottombanner {
    id
    slice_type
    primary {
      banner_background_image {
        url
        gatsbyImageData
        alt
      }
      banner_link {
        url
      }
      banner_link_text
      subtitle {
        text
      }
      title {
        text
      }
    }
  }
`
