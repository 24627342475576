import React from "react"

interface DotsIconProps extends React.SVGProps<SVGSVGElement> {
  width?: number
  height?: number
  color?: string
}

const OptionsIcon: React.FC<DotsIconProps> = ({
  width = 24,
  height = 24,
  color = "#C2C3C5",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8 6.2C10.8 5.537 11.337 5 12 5s1.2 0.537 1.2 1.2S12.663 7.4 12 7.4 10.8 6.863 10.8 6.2Z"
      fill={color}
    />
    <path
      d="M10.8 12c0-0.663 0.537-1.2 1.2-1.2s1.2 0.537 1.2 1.2-0.537 1.2-1.2 1.2-1.2-0.537-1.2-1.2Z"
      fill={color}
    />
    <path
      d="M10.8 17.8c0-0.663 0.537-1.2 1.2-1.2s1.2 0.537 1.2 1.2-0.537 1.2-1.2 1.2-1.2-0.537-1.2-1.2Z"
      fill={color}
    />
  </svg>
)

export default OptionsIcon
