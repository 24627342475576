import React, { Dispatch } from "react"
import "./ExpandedView.scss"
import { PrismicRichText } from "@prismicio/react"

interface ExpandedViewProps {
  setShow: Dispatch<boolean>
  content?: any
}

const ExpandedView: React.FC<ExpandedViewProps> = ({ setShow, content }) => {
  return (
    <div className="ExpandedViewFeatureSection">
      <div className="content">
        <div
          onClick={() => {
            setShow(false)
          }}
          className="header"
        >
          <p>Close</p>
        </div>
        <div className="body">
          <div className="header-section">{content?.name}</div>
          <div className="body-prisma-section">
            {content?.detailed_description?.richText.length > 0 && (
              <PrismicRichText
                field={content?.detailed_description?.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className="para">{children}</p>
                  ),
                  heading2: ({ children }) => (
                    <h2 className="heading-two">{children}</h2>
                  ),
                  image: ({ node }) => (
                    <div>
                      <img
                        src={node.url}
                        alt={node.alt || "Image"}
                        className="image"
                      />
                    </div>
                  ),
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpandedView
