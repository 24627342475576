import React, { useEffect, useRef, useState } from "react"
import "./HeroSectionV2.scss"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import AiInfoCard from "components/AIInfoCard/AiInfoCard"
import useCheckIsMobile from "hooks/useCheckIsMobile"
import OptionsIcon from "components/Icons/OptionsIcon"
import ArrowUpIcon from "components/Icons/UpperArrow"
import { gsap } from "gsap"

interface IHeroSectionProps {
  slice: any
}

const lowScore = {
  feedbackScore: 10,
  Specificity: 4,
  actionability: 1,
  tone: "Harsh",
  color: "#F54F1B",
}
const highScore = {
  feedbackScore: 92,
  Specificity: 85,
  actionability: 97,
  tone: "Candid",
  color: "#09B96E",
}
const HeroSectionV2: React.FC<IHeroSectionProps> = ({ slice }) => {
  const isMobile = useCheckIsMobile({ width: 700 })
  const [activeScore, setActiveScore] = useState(lowScore)
  const [hasMounted, setHasMounted] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const [isSent, setIsSent] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    backgroungimage = "",
    title = "",
    teambleaiicon = null,
    subtitle = null,
    form_fileld_input = "",
    form_field_label = "",
    companybannerheadertext = "",
    activebannerimage = {},
    activemobilebannerimage = {},
  } = slice?.primary
  const { items = [] } = slice
  const reviewItems = items?.filter(
    (item: any) => item?.type?.text?.toLowerCase() === "Review"?.toLowerCase()
  )
  const integration = items?.filter(
    (item: any) =>
      item?.type?.text?.toLowerCase() === "Integration"?.toLowerCase()
  )

  const companies = items?.filter(
    (item: any) => item?.type?.text?.toLowerCase() === "company"?.toLowerCase()
  )

  useEffect(() => {
    if (typeof window === "undefined") return
    const parentCurrent = parentRef?.current
    if (!parentCurrent) return
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation()
    }
    function addAnimation() {
      if (!parentCurrent) return
      const scrollers = parentCurrent.querySelectorAll(".scroller")
      scrollers.forEach(scroller => {
        scroller.setAttribute("data-animated", "true")
      })
    }
  }, [])

  const getMarginTop = () => {
    if (isMobile) {
      return "360px"
    } else {
      return "340px"
    }
  }
  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (!hasMounted || !inputRef.current) return

    const targetText = form_fileld_input?.text
    const textProxy = { value: 0 } // Use a number for smoother animation
    const tl = gsap.timeline({})
    // Start with empty input
    if (inputRef.current) {
      inputRef.current.value = ""
    }
    // Typing animation
    tl.to(textProxy, {
      value: targetText.length,
      duration: 1,
      ease: "none",
      onUpdate: () => {
        if (inputRef.current) {
          const currentLength = Math.floor(textProxy.value)
          inputRef.current.value = targetText.slice(0, currentLength)
        }
      },
    })
      .to({}, { duration: 0.2 })
      .to(".ai-button", {
        opacity: 0.5,
        duration: 0.5,
        yoyo: true,
        repeat: 1,
      })
      .to(".ai-form-field", {
        autoAlpha: 0,
        duration: 0.5,
        onComplete: () => {
          gsap.set(".ai-form-field", { display: "none" })
          gsap.set(".ai-info", { marginTop: "132px" })
        },
      })
      .to(".ai-info", {
        marginTop: getMarginTop(),
        duration: 0.5,
        ease: "power3.inOut",
      })
      .to(".ai-info-image", {
        display: "block",
        opacity: 1,
        duration: 0.5,
        ease: "power2.in",
        onComplete: () => {
          setActiveScore(highScore)
          setIsSent(true)
        },
      })
  }, [hasMounted])

  useEffect(() => {
    if (typeof window === "undefined") return

    let lastKnownScrollPosition = window.scrollY
    let ticking = false

    const handleScroll = () => {
      const integration = document.getElementById("integration-items-home")
      const footer = document.getElementById("footer-new-home")

      if (!integration || !footer) return

      const footerRect = footer.getBoundingClientRect()
      const integrationRect = integration.getBoundingClientRect()
      const windowHeight = window.innerHeight

      // More immediate calculation of intersection
      const footerTop = footerRect.top
      const integrationBottom = windowHeight - 40

      if (footerTop <= integrationBottom) {
        const distanceFromBottom = windowHeight - footerTop + 10
        integration.style.bottom = `${distanceFromBottom}px`
      } else {
        integration.style.bottom = "40px"
      }
    }

    const onScroll = () => {
      lastKnownScrollPosition = window.scrollY
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll()
          ticking = false
        })
        ticking = true
      }
    }

    window.addEventListener("scroll", onScroll)
    handleScroll() // Initial position

    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const renderImages = () => {
    if (!hasMounted) {
      return null // Or a loading placeholder
    }

    return (
      <>
        {isMobile ? (
          <img
            className="ai-info-image"
            src={activemobilebannerimage?.url}
            alt={activemobilebannerimage?.alt}
          />
        ) : (
          <img
            className="ai-info-image"
            src={activebannerimage?.url}
            alt={activebannerimage?.alt}
          />
        )}
      </>
    )
  }

  return (
    <div
      id="main-home-section"
      style={{
        backgroundImage: `url(${backgroungimage.url})`,
      }}
      className="MainHomeSection"
    >
      <div className="HeroSectionV2">
        {title && (
          <div className="title">
            {title.text.split(" ").map((word: string, index: number) =>
              word === "Superpowers" ? (
                <span key={index} className="title-gradient">
                  {word}
                </span>
              ) : (
                <span key={index}>{word} </span>
              )
            )}
          </div>
        )}
        <div className="ai-info-container">
          <div className="ai-form-field">
            <div className="ai-form-field-label-container">
              <div className="label">{form_field_label?.text ?? ""}</div>
              <OptionsIcon />
            </div>
            <div className="ai-input-container">
              <input
                ref={inputRef}
                defaultValue={""}
                className="input-container"
                disabled
              />
              <ArrowUpIcon />
            </div>
          </div>

          <div className="ai-info">
            {renderImages()}
            <div className="ai-info-content">
              {teambleaiicon.gatsbyImageData && (
                <GatsbyImage
                  image={teambleaiicon.gatsbyImageData}
                  alt={"TeambleAiIcon"}
                  objectFit="contain"
                />
              )}
              <AiInfoCard
                showProgress={true}
                label="AI Feedback Score:"
                score={activeScore?.feedbackScore}
                total={100}
                color={activeScore?.color}
                hideTotal={true}
              />
              {!isMobile && (
                <AiInfoCard
                  showProgress={true}
                  label="Specificity:"
                  score={activeScore?.Specificity}
                  total={100}
                  color={activeScore?.color}
                  hideTotal={true}
                />
              )}
              {!isMobile && (
                <AiInfoCard
                  showProgress={true}
                  label="Actionability:"
                  score={activeScore?.actionability}
                  color={activeScore?.color}
                  total={100}
                  hideTotal={true}
                />
              )}
              {!isMobile && (
                <AiInfoCard
                  showProgress={true}
                  label="Tone:"
                  score={activeScore?.tone}
                  color={activeScore?.color}
                  hideTotal={true}
                />
              )}
            </div>
            {!isMobile && (
              <div className="ai-button">
                {isSent ? "Send feedback" : "Rewrite with AI"}
              </div>
            )}
          </div>
        </div>
        {subtitle?.text && <div className="subtitle">{subtitle?.text}</div>}

        <div className="reviewItems">
          {reviewItems?.map((item: any, index: number) => (
            <div key={index} className="reviewInfo">
              {item?.icon_image?.gatsbyImageData && (
                <GatsbyImage
                  image={item?.icon_image?.gatsbyImageData}
                  alt={item?.icon_image?.alt ?? "Review item icons"}
                  objectFit="contain"
                />
              )}
              {item?.icon_name?.text ?? ""}
            </div>
          ))}
        </div>
        <div className="integrationItems" id="integration-items-home">
          {integration?.map((item: any, index: number) => (
            <Link
              to={item?.icon_link?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div key={index} className="integrationInfo">
                {item?.icon_image?.gatsbyImageData && (
                  <GatsbyImage
                    image={item?.icon_image?.gatsbyImageData}
                    alt={item?.icon_image?.alt ?? "Review item icons"}
                    objectFit="contain"
                  />
                )}
                <div className="mobile-text">{item?.mobiletext?.text}</div>
                <div className="desktop-text">{item?.icon_name?.text}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div ref={parentRef} className="CompanyBannerSection">
        <div className="headerText">{companybannerheadertext?.text ?? ""}</div>
        <div className="scroller">
          <div className="scroll-inner">
            {companies?.map((val: any, index: number) => (
              <div key={index} className="imageBlock">
                <GatsbyImage
                  alt={val?.icon_image?.alt}
                  image={val?.icon_image?.gatsbyImageData}
                  objectFit="contain"
                />
              </div>
            ))}
            {companies?.map((val: any, index: number) => (
              <div key={index} className="imageBlock test">
                <GatsbyImage
                  alt={val?.icon_image?.alt}
                  image={val?.icon_image?.gatsbyImageData}
                  objectFit="contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSectionV2

export const query = graphql`
  fragment TeambleV2HeroSectionData on PrismicTeamblev2homepageDataBodyTeamblev2herosection {
    id
    primary {
      backgroungimage {
        alt
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        url
      }
      activebannerimage {
        alt
        gatsbyImageData
        url
      }
      activemobilebannerimage {
        alt
        gatsbyImageData
        url
      }
      teambleaiicon {
        alt
        url
        gatsbyImageData
      }
      form_field_label {
        text
      }
      form_fileld_input {
        text
      }
      companybannerheadertext {
        text
      }
      subtitle {
        text
      }
      title {
        text
      }
    }
    items {
      icon_image {
        alt
        gatsbyImageData
        url
      }
      icon_link {
        url
      }
      icon_name {
        text
      }
      type {
        text
      }
      mobiletext {
        text
      }
    }
  }
`
