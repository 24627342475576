import { graphql, useStaticQuery } from "gatsby"

export const useFooterV2Data = () => {
  return useStaticQuery(
    graphql`
      {
        prismicFooterv2 {
          data {
            aboutcompany {
              field_name
              url_field {
                url
              }
            }
            soc2 {
              alt
              url
              gatsbyImageData
            }
            companylogo {
              alt
              url
              gatsbyImageData
            }
            companyresources {
              field_name
              url_field {
                url
              }
            }
            copyright {
              text
            }
            sociallinks {
              icon {
                alt
                gatsbyImageData
                url
              }
              iconlink {
                url
              }
            }
          }
        }
      }
    `
  ).prismicFooterv2?.data
}
