import React, { useState } from "react"
import "./Video.scss"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PlayIcon from "components/ReactSvgIcons/PlayIcon"

interface VideoSectionProps {
  slice: any
}

const Video: React.FC<VideoSectionProps> = ({ slice }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  const handlePlaceholderClick = () => {
    setIsVideoLoaded(true)
  }

  return (
    <div className="Video">
      <div className="header-section">
        <div className="title">{slice?.primary?.title?.text}</div>
        <div className="logo">
          <GatsbyImage
            image={slice?.primary?.icon?.gatsbyImageData}
            alt={slice?.primary?.icon?.alt}
            objectFit="contain"
          />
        </div>
      </div>
      <div className="placeholder-image-container">
        {!isVideoLoaded ? (
          <div
            className="placeholder-image"
            onClick={handlePlaceholderClick}
            style={{
              backgroundImage: `url(${slice?.primary?.placeholderimage?.url})`,
              cursor: "pointer",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(255, 255, 255, 0.8)",
                borderRadius: "50%",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <PlayIcon width={42} height={42} color="#0a0d16" />
            </div>
          </div>
        ) : (
          <video
            width="100%"
            controls
            autoPlay
            poster={slice?.primary?.placeholderimage?.url}
            preload="auto"
            style={{
              borderRadius: "28px",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source
              src="https://teamble.com/teamble-2.0.mp4"
              type="video/mp4"
            />
          </video>
        )}
      </div>
    </div>
  )
}

export default Video

export const query = graphql`
  fragment videoSectionTestimonial on PrismicTeamblev2homepageDataBodyVideo {
    id
    primary {
      icon {
        alt
        gatsbyImageData
        url
      }
      placeholderimage {
        alt
        gatsbyImageData
        url
      }
      title {
        text
      }
    }
  }
`
