import { useState, useEffect } from "react"

interface CheckIsMobileProps {
  width: number
}
const useCheckIsMobile = ({ width }: CheckIsMobileProps) => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth <= width : false
  )

  useEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => {
      setIsMobile(window.innerWidth <= width)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [width])

  return isMobile
}
export default useCheckIsMobile
