import { graphql, useStaticQuery } from "gatsby"

export const useNavigationDataV2 = () => {
  return useStaticQuery(
    graphql`
      {
        prismicNavigationv2 {
          data {
            navlinks {
              name
              url {
                url
              }
            }
            company_logo {
              alt
              gatsbyImageData
              url
            }
            navigationicon {
              url
              gatsbyImageData
              alt
            }
            closeicon {
              alt
              gatsbyImageData
              url
            }
            imagelinks {
              icon {
                alt
                gatsbyImageData
                url
              }
              url {
                url
              }
            }
          }
        }
      }
    `
  ).prismicNavigationv2.data
}
