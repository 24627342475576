import React from "react"

interface ArrowUpIconProps extends React.SVGProps<SVGSVGElement> {
  width?: number
  height?: number
  color?: string
  backgroundColor?: string
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({
  width = 36,
  height = 36,
  color = "#FAFAFA",
  backgroundColor = "#0A0D16",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="36" height="36" rx="18" fill={backgroundColor} />
    <path
      d="M22.6667 16.5L18 12M18 12L13.3334 16.5M18 12V24"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowUpIcon
