import React from "react"
import "./ProductFeaturedSection.scss"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

interface ProductFeaturedSectionProps {
  slice: any
}

const ProductFeaturedSection: React.FC<ProductFeaturedSectionProps> = ({
  slice,
}) => {
  return (
    <div className="ProductFeaturedSection">
      <div className="featured-image">
        {slice?.items?.map((item: any, index: number) => (
          <div key={index} className="image-item">
            <GatsbyImage
              image={item?.featuresection.gatsbyImageData}
              alt={item?.featuresection?.alt}
              objectFit="contain"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductFeaturedSection

export const query = graphql`
  fragment productFeatured on PrismicTeamblev2homepageDataBodyFeatured {
    id
    items {
      featuresection {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
