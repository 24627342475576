import { PricingTableSection } from "./pricingTableSection/pricingTableSection"
import { ClientTestimonials } from "./clientTestimonials/clientTestimonials"
import { CustomerLogosSection } from "./customerLogosSection/customerLogosSection"
import { DoItAllSection } from "./doItAllSection/doItAllSection"
import { FooterLinks } from "./footer/footerLinks"
import { HeroSection } from "./heroSection/heroSection"
import { HowItWorksSection } from "./howItWorksSection/howItWorksSection"
import { QuoteSection } from "./quoteSection/quoteSection"
import { VideoSection } from "./videoSection/videoSection"
import { FaqSection } from "./faqSection/faqSection"
import { CallToActionSection } from "./callToActionSection/callToActionSection"
import { BlogDetailsSection } from "./blogDetailsSection/blogDetailsSection"
import { AddressSection } from "./addressSection/addressSection"
import HeroSectionV2 from "./V2HeroSection/HeroSectionV2"
import ClientTestimonialNew from "./ClientTestimonialV2/ClientTestimonialNew"
import ProductFeaturedSection from "./productFeaturedSection/ProductFeaturedSection"
import Video from "./VideoSectionv2/Video"
import BottomBanner from "./BottomBanner/BottomBanner"
import OperationsBanner from "./OperationsBanner/OperationsBanner"
import AdvisoryBanner from "./AdvisoryBanner/AdviosryBanner"
import Features from "./Features/Features"
export { default as AddToButtons } from "./addToButtons/addToButtons"
export { default as Footer } from "./footer/footer"
export { default as Header } from "./header"
export { default as Layout } from "./layout"
export { default as NavigationBar } from "./navigationBar/navigationBar"
export { default as SEO } from "./seo"

export const sliceComponents = {
  footer_links: FooterLinks,
  hero_section: HeroSection,
  customer_logos: CustomerLogosSection,
  client_testimonials: ClientTestimonials,
  teamble_video: VideoSection,
  quote_section: QuoteSection,
  how_it_works_section: HowItWorksSection,
  slack___ms_teams_section: DoItAllSection,
  pricing_table: PricingTableSection,
  faq_section: FaqSection,
  call_to_action: CallToActionSection,
  blog: BlogDetailsSection,
  address: AddressSection,
}

export const sliceComponentV2 = {
  teamblev2herosection: HeroSectionV2,
  client_testimonials: ClientTestimonialNew,
  featured: ProductFeaturedSection,
  video: Video,
  bottombanner: BottomBanner,
  operations_banners: OperationsBanner,
  advisorbanner: AdvisoryBanner,
  product_feature_section_: Features,
}
