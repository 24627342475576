import React from "react"
import "./AdvisoryBanner.scss"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

interface AdvisoryBannerProps {
  slice: any
}

const AdvisoryBanner: React.FC<AdvisoryBannerProps> = ({ slice }) => {
  const {
    title = {},
    role = "",
    text = "",
    description = {},
    adam_grant_image,
  } = slice?.primary

  return (
    <div className="AdvisoryBanner">
      <div className="banner">
        <div className="info">
          <div className="title">{title?.text}</div>
          <div className="desc">{description?.text}</div>
          <div className="name">{text}</div>
          <div className="role">{role}</div>
        </div>
        <div className="image">
          <img
            alt={adam_grant_image?.alt}
            src={adam_grant_image?.url}
            className="adamImage"
          />
        </div>
      </div>
    </div>
  )
}

export default AdvisoryBanner

export const query = graphql`
  fragment advisoryBanner on PrismicTeamblev2homepageDataBodyAdvisorbanner {
    id
    primary {
      title {
        text
      }
      text
      role
      description {
        text
      }
      adam_grant_image {
        alt
        gatsbyImageData
        url
      }
    }
    slice_type
  }
`
