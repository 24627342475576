import React, { useEffect, useRef, useState } from "react"
import "./ClientTestimonialNew.scss"
import { graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

interface ClientTestimonialNewProps {
  slice: any
}

const ClientTestimonialNew: React.FC<ClientTestimonialNewProps> = ({
  slice,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [activeSlide, setActiveSlide] = useState<any>(null) // Store active slide data

  const sliderRef = useRef<Slider | null>(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true,
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentIndex(newIndex),
    afterChange: (currentSlide: number) => {
      // Set the active slide data after slide change
      setActiveSlide(slice?.items?.[currentSlide] || null)
    },
  }
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  useEffect(() => {
    if (slice?.items?.length > 0) {
      setActiveSlide(slice.items[0])
    }
  }, [slice?.items])

  return (
    <div className="ClientTestimonialNew">
      <div className="slider-container">
        <Slider ref={sliderRef} {...settings}>
          {slice?.items?.map((item: any, index: number) => (
            <div key={index} className="testimonialBox">
              <div className="info-box">
                <div className="info-image">
                  <GatsbyImage
                    image={item.client_image.gatsbyImageData}
                    alt={item.client_image.alt}
                    objectFit="contain"
                  />
                </div>
                <div className="info-text">
                  <div className="title">{item?.testimonial_title ?? ""}</div>
                  <div className="desc">{item?.detailed_testimonial ?? ""}</div>
                  <div className="designation">
                    <div className="name">{item?.client_name}</div>
                    <div className="role">{item?.client_designation}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="count-container">
        <div className="company_icon">
          <GatsbyImage
            image={activeSlide?.client_company_logo?.gatsbyImageData}
            alt={activeSlide?.client_company_logo?.alt}
            objectFit="cover"
            style={{
              width: "126px",
              height: "33px",
            }}
          />
        </div>
        <div className="count">
          <div className="review">
            <span className="current">{currentIndex + 1}</span>
            <span className="total">/{slice?.items?.length} reviews</span>
          </div>
          <div className="icons">
            <div onClick={handlePrev} className="icon-container">
              <GatsbyImage
                image={slice?.primary?.leftarrowicon.gatsbyImageData}
                alt={slice?.primary?.leftarrowicon.alt}
                objectFit="contain"
              />
            </div>
            <div onClick={handleNext} className="icon-container">
              <GatsbyImage
                image={slice?.primary?.rightarrowicon.gatsbyImageData}
                alt={slice?.primary?.rightarrowicon.alt}
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientTestimonialNew

export const query = graphql`
  fragment clientTestimonialsV2 on PrismicTeamblev2homepageDataBodyClientTestimonials {
    id
    items {
      client_company_logo {
        alt
        url
        gatsbyImageData
      }
      client_designation
      client_name
      detailed_testimonial
      testimonial_title
      client_image {
        gatsbyImageData
        alt
        url
      }
    }
    primary {
      rightarrowicon {
        alt
        gatsbyImageData
        url
      }
      leftarrowicon {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
