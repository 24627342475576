import React from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import "./AiInfoCard.scss"

interface AiInfoCardProps {
  label: string
  showProgress?: Boolean
  score: number | string
  color: string
  hideTotal: boolean
  total?: number
}

function AiInfoCard({
  label,
  showProgress,
  score,
  total,
  color,
  hideTotal,
}: AiInfoCardProps) {
  return (
    <div className="AiInfoCard">
      {label && <div className="label">{label}</div>}
      {showProgress && typeof score === "number" && total && (
        <div
          style={{
            width: 16,
            height: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgressbar
            value={(score / total) * 100}
            styles={buildStyles({
              pathColor: color, // Progress color (orange)
              trailColor: "#E0E0E0", // Background color
              strokeLinecap: "round", // Rounded progress edges
              textColor: "#333",
              pathTransitionDuration: 0.5,
            })}
          />
        </div>
      )}
      <div className="scoreInfo">
        <div
          style={{
            color: color,
          }}
          className="score"
        >
          {score}
        </div>
        {total && !hideTotal && <div className="total">/{total}</div>}
      </div>
    </div>
  )
}

export default AiInfoCard
