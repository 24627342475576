import React, { useEffect, useState } from "react"
import "./Navigation.scss"
import { useNavigationDataV2 } from "hooks/useNavigationDataV2"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Navigation = () => {
  const {
    company_logo = {},
    imagelinks = [],
    navlinks = [],
    navigationicon = {},
    closeicon = {},
  } = useNavigationDataV2()
  const [scrollY, setScrollY] = useState(0)
  const [prevScrollY, setPrevScrollY] = useState(0)
  const [isScrollingUp, setIsScrollingUp] = useState(true)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
      setIsScrollingUp(window.scrollY < prevScrollY)
      setPrevScrollY(window.scrollY)
      setIsMobileMenuOpen(false)
      setIsScrolled(window.scrollY > 100)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [prevScrollY])

  return (
    <>
      <div
        style={
          isMobileMenuOpen
            ? {
                backgroundColor: "#fff",
              }
            : {}
        }
        className={`Navigation ${isScrolled ? "scrolled" : ""} ${
          !isScrollingUp && scrollY > 200 ? "hidden" : ""
        }`}
      >
        <div>
          <Link to="/">
            <GatsbyImage
              alt={company_logo?.alt}
              image={company_logo?.gatsbyImageData}
            />
          </Link>
        </div>
        <div className="nav-items">
          <div className="text-links">
            {navlinks?.map((item: any, index: number) => (
              <a key={index} className="links" href={item?.url?.url}>
                {item?.name}
              </a>
            ))}
          </div>
          <div className="image-links">
            <div onClick={toggleMobileMenu} className="mobile-nav-open">
              <GatsbyImage
                alt={isMobileMenuOpen ? closeicon.alt : navigationicon?.alt}
                image={
                  isMobileMenuOpen
                    ? closeicon.gatsbyImageData
                    : navigationicon?.gatsbyImageData
                }
              />
            </div>
            {imagelinks?.map((item: any, index: number) => (
              <Link
                key={index}
                className="links"
                to={item?.url?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GatsbyImage
                  alt={item?.icon?.alt}
                  image={item?.icon?.gatsbyImageData}
                  className="image"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-content">
          {navlinks?.map((item: any, index: number) => (
            <a
              key={index}
              href={item?.url?.url}
              rel="noopener noreferrer"
              className="mobile-link"
            >
              {item?.name}
            </a>
          ))}
        </div>
      </div>
    </>
  )
}

export default Navigation
