import React, { useEffect, useState } from "react"
import "./Features.scss"
import { graphql, navigate } from "gatsby"
import ExpandedView from "./ExpandedView/ExpandedView"

interface FeaturesProps {
  slice: any
}

const Features: React.FC<FeaturesProps> = ({ slice }) => {
  const [active, setActive] = useState<any>({})
  const [show, setShow] = useState(false)
  useEffect(() => {
    slice?.items[0] && setActive(slice?.items[0])
  }, [slice?.items])

  const { backgroundimg = {}, headertitle = "" } = slice?.primary

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash // Get the part after #
      if (hash === "#features") {
        const section = document.getElementById("features")
        if (section) {
          section.scrollIntoView({ behavior: "smooth" })
        }
      }
    }
  }, [])

  useEffect(() => {
    if (show && typeof window !== "undefined") {
      // Target the HTML element instead of body
      document.documentElement.style.overflow = "hidden"
    } else if (typeof window !== "undefined") {
      document.documentElement.style.overflow = ""
    }

    return () => {
      if (typeof window !== "undefined") {
        document.documentElement.style.overflow = ""
      }
    }
  }, [show])
  return (
    <>
      <div
        id="features"
        style={{
          backgroundImage: `url(${backgroundimg.url})`,
        }}
        className="Features"
      >
        <div className="headerTitle">{headertitle}</div>
        <div className="item-list">
          {slice?.items?.map((item: any, index: any) => (
            <div
              onClick={() => {
                setActive(item)
              }}
              className={`key-names ${
                active.name === item?.name ? "key-names-active" : ""
              }`}
              key={index}
            >
              <img
                alt={item?.iconimage?.alt}
                src={item?.iconimage?.url}
                className="icon"
              />
              {item?.name}
            </div>
          ))}
        </div>
        <div className="line">
          <div className="line-container"></div>
        </div>
        <div className="desc-section">
          <div className="desc">
            <div className="title">{active?.name}</div>
            <div className="desc-container">
              <div
                className="desc-description"
                dangerouslySetInnerHTML={{
                  __html: active?.description?.html || "",
                }}
              />
              {active?.detailed_description?.richText.length > 0 && (
                <div className="find-btn" onClick={() => setShow(true)}>
                  Find out more
                </div>
              )}
            </div>
          </div>
          <div className="image">
            <img
              alt={active?.featureimage?.alt}
              src={active?.featureimage?.url}
              className="feature-img"
            />
          </div>
        </div>
      </div>
      {show && <ExpandedView setShow={setShow} content={active} />}
    </>
  )
}

export default Features

export const query = graphql`
  fragment TeambleFeatures on PrismicTeamblev2homepageDataBodyProductFeatureSection {
    items {
      name
      iconimage {
        alt
        gatsbyImageData
        url
      }
      featureimage {
        alt
        gatsbyImageData
        url
      }
      description {
        richText
        text
        raw
        html
      }
      detailed_description {
        text
        richText
        raw
        html
      }
    }
    primary {
      headertitle
      backgroundimg {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
