import React from "react"
import "./OperationsBanner.scss"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

interface OperationsBannerProps {
  slice: any
}
const OperationsBanner: React.FC<OperationsBannerProps> = ({ slice }) => {
  const { title = {}, subtitle = {}, banner_image = {} } = slice?.primary
  return (
    <div className="OperationsBanner">
      <div className="info">
        <div className="title">{title?.text}</div>
        <div className="title">{subtitle?.text}</div>
      </div>
      <div className="image-section">
        <GatsbyImage
          alt={banner_image?.alt}
          image={banner_image?.gatsbyImageData}
        />
      </div>
    </div>
  )
}

export default OperationsBanner

export const query = graphql`
  fragment operationsBanner on PrismicTeamblev2homepageDataBodyOperationsBanners {
    id
    slice_type
    primary {
      title {
        text
      }
      subtitle {
        text
      }
      banner_image {
        alt
        url
        gatsbyImageData
      }
    }
  }
`
